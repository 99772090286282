import {BaseController} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/controller-factory/BaseController';
import {ControllerParams} from '@wix/yoshi-flow-editor';
import {appClient, Scope} from '@wix/app-settings-client';
import {APP_DEFINITION_ID, APP_SETTINGS_CDN, StoresWidgetID} from '@wix/wixstores-client-core/dist/es/src/constants';
import _ from 'lodash';
import {withErrorReporting} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/errorReporter';
import {PRODUCT_PAGE_WIXCODE_APP_NAME, productPageFedopsEvent} from '../constants';
import {wixCodeGetProduct} from '../services/getProduct';
import {IFedOpsLogger} from '@wix/native-components-infra/dist/es/src/types/types';
import {ProductPageStore} from './ProductPageStore';
import {SPECS} from '../specs';
import {IProductPageControllerConfig} from '../types/app-types';
import {createSlotVeloAPIFactory} from '@wix/widget-plugins-ooi/velo';
import {IWidgetControllerConfig} from '@wix/native-components-infra';

export class ProductPageController extends BaseController {
  private productPageStore: ProductPageStore;
  private config: IProductPageControllerConfig;
  private readonly fedopsLogger: IFedOpsLogger;
  private readonly isResponsive: boolean;
  private readonly controllerConfig: IWidgetControllerConfig;

  constructor(controllerParams: ControllerParams) {
    super(controllerParams);
    const isMobile = this.siteStore.isMobile();
    const fedopsLoggerFactory = this.siteStore.platformServices.fedOpsLoggerFactory;
    this.fedopsLogger = fedopsLoggerFactory.getLoggerForWidget({
      appId: APP_DEFINITION_ID,
      widgetId: StoresWidgetID.PRODUCT_PAGE,
      appName: PRODUCT_PAGE_WIXCODE_APP_NAME,
    });
    /* istanbul ignore next: todo: test */
    this.isResponsive = controllerParams.controllerConfig.config.style.styleParams?.booleans?.responsive || isMobile;
    this.controllerConfig = controllerParams.controllerConfig;
    this.config = _.clone(controllerParams.controllerConfig.config);
    const isEditor = typeof window !== 'undefined' && window.Wix;
    /* istanbul ignore else: todo(flow-editor): test */
    if (isEditor) {
      this.listenToAppSettingsUpdate();
    }
  }

  private listenToAppSettingsUpdate() {
    const appSettingsClient = appClient({scope: Scope.COMPONENT, cdnUrl: APP_SETTINGS_CDN});
    appSettingsClient.onChange((pb) => {
      this.productPageStore.updateState(this.config, {
        APP: undefined,
        COMPONENT: {},
        appSettings: pb,
      });
    });
  }

  public exports = () =>
    withErrorReporting(this.flowAPI.reportError)({
      getProduct: () => {
        const fedopsInteraction = this.siteStore.isSSR()
          ? productPageFedopsEvent.WixCodeGetProductSSR
          : productPageFedopsEvent.WixCodeGetProduct;
        this.fedopsLogger.interactionStarted(fedopsInteraction);
        const onSuccess = () => this.fedopsLogger.interactionEnded(fedopsInteraction);
        return wixCodeGetProduct(this.siteStore, this.flowAPI.reportError, onSuccess);
      },
      getCustomTextFieldsValues: (): string[] => {
        const fedopsInteraction = this.siteStore.isSSR()
          ? productPageFedopsEvent.WixCodeGetCustomTextFieldValuesSSR
          : productPageFedopsEvent.WixCodeGetCustomTextFieldValues;
        this.fedopsLogger.interactionStarted(fedopsInteraction);
        const customTextFieldsValues = this.productPageStore.userInputs.text;
        this.fedopsLogger.interactionEnded(fedopsInteraction);
        return customTextFieldsValues;
      },
    });

  public onConfigUpdate = (config: IProductPageControllerConfig) => {
    const {publicData} = config;
    this.config = _.clone(config);
    return this.productPageStore.updateState(this.config, publicData);
  };

  /* istanbul ignore next: todo test */
  public onAppSettingsUpdate = (updates: {[key: string]: any}) => {
    if (
      this.siteStore.experiments.enabled(SPECS.EDITOR_OOI) &&
      updates.scope === Scope.COMPONENT &&
      updates.source === 'app-settings'
    ) {
      this.productPageStore.updateAppSettings(updates.payload);
    }
  };

  public getFreeTexts = (): string[] => {
    return [];
  };

  public readonly load = async () => {
    const {publicData} = this.config;
    if (this.productPageStore) {
      return;
    }

    this.productPageStore = new ProductPageStore(
      this.config,
      publicData,
      this.setProps.bind(this),
      this.siteStore,
      this.controllerConfig.config.externalId || '',
      this.flowAPI.reportError,
      this.controllerConfig.compId,
      createSlotVeloAPIFactory(this.controllerConfig),
      this.controllerConfig
    );

    await this.productPageStore.setInitialState().catch(this.flowAPI.reportError);
  };

  public readonly init = async () => {
    await this.load();
  };
}
